<template>
  <div
    class="notification-content-item"
    :class="index % 2 === 0 ? 'bg-red' : 'bg-black'"
    v-if="invite"
  >
    <div class="notification-content-item-avatar flex-center">
      <Avatar :url="getBaseAvatarUrl + '0.png'" size="90" />
    </div>
    <div class="notification-content-item-content flex-center">
      <span class="notification-content-item-content-text">
        {{
          this.$t("toast.inviteRequestText", {
            name: this.getFriendData.user.name,
            game: this.requestGameNameText(
              this.gameTextComputed(getFriendData.user.game)
            ),
          })
        }}
      </span>
    </div>

    <div
      class="notification-content-item-reject ui-button ui-button-small-red flex-center"
      @click="$emit('action', false, invite.userId)"
    >
      <span>{{ $t("general.reject") }}</span>
    </div>
    <div
      class="notification-content-item-accept ui-button ui-button-small-green flex-center"
      @click="$emit('action', true, invite.userId)"
    >
      <span>{{ $t("general.accept") }}</span>
    </div>
  </div>
</template>

<script>
import Helpers from "@/mixin/Helpers";
import { mapGetters, mapState } from "vuex";

export default {
  name: "NotificationItem",
  mixins: [Helpers],
  components: {
    Avatar: () => import("@/components/Misc/Avatar"),
  },
  props: {
    index: { type: Number, default: 0 },
    invite: { type: [Object, Boolean], default: false },
  },
  computed: {
    ...mapGetters("friends", ["getFriendsById"]),
    getFriendData() {
      return this.getFriendsById(this.invite.userId);
    },
    getBaseAvatarUrl() {
      return this.getFriendData.user.avatar;

      // return this.getFriendData.user.avatar
    },
  },
  methods: {
    requestGameNameText(text) {
      return text === "Texas"
        ? `Texas Poker`
        : text === "Omaha"
        ? "Omaha Poker"
        : text;
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-content-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  min-height: 110px;
  border-radius: 15px;
  background-color: rgba($color: #3b0b14, $alpha: 1);
  margin-bottom: 10px;
  &-avatar {
    width: 7%;
  }
  &-content {
    width: 65%;
    height: 85px;
    background-color: rgba($color: #791d2a, $alpha: 1);
    border-radius: 50px;
    margin: 0 20px;
    &-text {
      font-size: 34px;
      text-shadow: 0 2px 3px #000000;
    }
    &-item-reject {
    }
  }
  .ui-button-small-green {
    margin-left: 15px;
  }
}
</style>
